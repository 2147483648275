<template>
  <div>
    <h2 class="main-title" id="mainTitle">{{pageTitle}}</h2> 
    <div class="filter-panel">
      Выберите период времени:
      <flat-pickr width=200
        id="dateSelector"
        class="datebox-container"
        v-model="currentDatesText"
        :config="flatPickrOptions"
      >
      </flat-pickr>
    <!-- <vs-checkbox class="deduplication-mode-switcher" v-model="deduplication_mode" @change="dedupModeChanged">Показывать дедуплицированный поток</vs-checkbox> -->
    <vs-button class="help-button" color="white" type="line" @click="onHelpButtonClick">?</vs-button>
    </div>
    <div class="main">
      <span class="master-container" >
        <main-ners-grid 
          :dataSource="masterData"
          ref="mainGrid"
          @selected-rows-changed="onSelectedRowsChanged"
        />
      </span>
      <span class="detail-container" >
        <details-ners-grid 
          :dataSource="detailsData"
        />
      </span>
    </div>
    <span class="dymanic-detail-container" >
      <ners-dynamic-chart :dataSource="chartData" :chartOptions="chartOptions" />
    </span>
    <v-tour name="n1ViewTour" :steps="tourSteps" :options="tourOptions">
    </v-tour>
  </div>

</template>

<script>
import mainNersGridWrapper from "@/components/ners/MainNersGridWrapper.vue"
import detailsNersGridWrapper from "@/components/ners/DatailsNersGridWrapper.vue"
import nersDynamicChartWrapper from "@/components/ners/NersDynamicChartWrapper.vue"
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


export default {
  name: "TopNERS",
  components: {
    flatPickr,
    "main-ners-grid": mainNersGridWrapper,
    "details-ners-grid": detailsNersGridWrapper,
    "ners-dynamic-chart": nersDynamicChartWrapper
  },
  data() {
    return {
      tourSteps: [{
          target: "#mainTitle",
          header: {
            title: "Добро пожаловать!",
          },
          content: "Сейчас вы находитесь на вкладке 'Ключевые объекты дня'"
        }, {
          target: "#mainTitle",
          header: {
            title: "Добро пожаловать!",
          },
          content: "Здесь вы найдёте информацию по объектам за прошлые дни."
        }, {
          target: "#dateSelector",
          header: {
            title: "Зачем это поле?",
          },
          content: "Кликните здесь что бы выбрать интересующий вас временной отрезок. Помните что чем больше данных вы выбираете тем больше может потребоваться времени на загрузку."
        }, {
          target: "#mainGridTitle",
          header: {
            title: "Что здесь?",
          },
          content: "В этой таблице вы найдёте выделенные слова/словосочетания и их статистики."
        }, {
          target: "#typeCol",
          header: {
            title: "Что за LOC/PER/ORG?",
          },
          content: "В этой колонке отмечен тип объекта который мы распознали: Локация/Персона/Организация"
        }, {
          target: "#quantCol",
          header: {
            title: "Что за цифры?",
          },
          content: "В этой колонке вы найдете суммарное количество упоминарий слова/словосочетания за выбранный периуд"
        }, {
          target: "#percCol",
          header: {
            title: "Что за цифры?",
          },
          content: "В этой колонке вы найдете падало или росло количество упоминаний по отношению к предыдущему сходному периуду"
        }, {
          target: '#zCol',  
          header: {
            title: "Что значат эти цифры?",
          },
          content: "Мы высчитываем насколько аномально текущее количество упоминаний слова/словосочетания. Значения больше 2.85 считаются аномально большими, меньше -2.85 - аномально малыми, остальные - в рамках нормы"
        }, {
          target: ".td-check",
          header: {
            title: "Что за квадратики?",
          },
          content: "Вы можете выделить отденые строчки что бы получить более детальную информацию"
        }, {
          target: "#detailsTitle",
          header: {
            title: "Что в этой таблице?",
          },
          content: "Тут вы найдет более детальную информацию о выбранных словах"
        }, {
          target: "#keyWordsCol",
          header: {
            title: "Что за слова?",
          },
          content: "Для каждого слова/словосочетания мы выделии ключевые слова. Ключевые слова могу меняться день ко дню. Они нужны что бы показывать контекст в котором циркулировал объект"
        }, {
          target: "#sourceMessageCol",
          header: {
            title: "Что это за колонка?",
          },
          content: "Тут вы найдете несколько исходных сообщений."
        }, {
          target: "#chartContainer",
          header: {
            title: "Что полезного здесь?",
          },
          content: "Тут вы увидите динамику упоминаний по дням."
        }, {
          target: "#mainTitle",
          header: {
            title: "Пожелания!",
          },
          content: "Приятного вам использования сервиса!"
        }
      ], 
      tourOptions: {
        highlight: false,
        labels: {
          buttonSkip: "Пропустить обучение",
          buttonPrevious: 'Назад',
          buttonNext: 'Дальше',
          buttonStop: 'Закончить'
        }
      },      
      pageTitle: "Ключевые объекты дня",
      deduplication_mode: true,
      flatPickrOptions: {
        mode: "range",
        maxDate: new Date().setDate(new Date().getDate()-1),
        dateFormat: "Y-m-d",
        defaultDate: [new Date().setDate(new Date().getDate()-1), new Date().setDate(new Date().getDate()-1)],
        onChange: this.onChangeHandler,
      },
      timeseries: [],
      currentDatesText: "",
      popupVisible: false,
      data: [],
      currentDateRange: [],
      masterData: [],
      detailsData: [],
      chartData: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Динамика',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
    }
  
  },
  methods: {
    onHelpButtonClick(e) {
      this.$tours["n1ViewTour"].start();
    },
    onSelectedRowsChanged(e) {
      if (e.selectedRows.length > 0) {
        let targetNers = e.selectedRows.map(i => i.ner_text);
        this.detailsData = this.data.filter((r) => targetNers.some(i => i==r.ner_text));
        this.chartData = this.timeseries.filter(i => targetNers.indexOf(i.text) != -1);
      } else {
        this.detailsData = this.data;
        let uniqueNers = this.masterData.map(i => i.ner_text).slice(0, 4);
        this.chartData = this.timeseries.filter(i => uniqueNers.indexOf(i.text) != -1);
      }
    },
    convertDateToYMD(date) {
      let mm = (date.getMonth() + 1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let dd = date.getDate().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let yy = date.getFullYear();
      return yy + '-' + mm + '-' + dd;
    },
    onChangeHandler(e) {
      if (e.length > 1) {
        this.$refs.mainGrid.resetSelection();
        let startDate = this.convertDateToYMD(e[0]);
        let endDate = this.convertDateToYMD(e[1]);
        this.currentDateRange = [startDate, endDate];
        this.loadData(startDate, endDate);
      }
    },
    // dedupModeChanged(e) {
    //   this.$refs.mainGrid.resetSelection();
    //   if (this.currentDateRange.length > 0) 
    //     this.loadData(this.currentDateRange[0], this.currentDateRange[1])  
    //   else 
    //     this.loadData(
    //       this.convertDateToYMD(new Date(this.flatPickrOptions.defaultDate[0])),
    //       this.convertDateToYMD(new Date(this.flatPickrOptions.defaultDate[1])),
    //     );
    // },
    loadData(startDate, endDate) {
      let that = this;
      let request_data = {
          startDate : startDate,
          endDate : endDate,
          pageSize: 300
      };

      this.$vs.loading();
      let url = '' + this.$hostname + "/1n" + ((this.deduplication_mode)?"/get-1n-ner-dedup":"/get-1n-ner");
      this.axios({
        method: 'post',
        url: url,
        data: request_data,
      }).then((res) => {

        that.masterData = res.data.masterData

        that.detailsData = res.data.detailsData;
        that.data = res.data.detailsData;
        that.timeseries = res.data.timeseries;

        let uniqueNers = that.masterData.map(i => i.ner_text).slice(0, 4);
        that.chartData = that.timeseries.filter(i => uniqueNers.indexOf(i.text) != -1);
        that.$vs.loading.close();
      });

    }
  },
  mounted() {
    this.loadData(
      this.convertDateToYMD(new Date(this.flatPickrOptions.defaultDate[0])),
      this.convertDateToYMD(new Date(this.flatPickrOptions.defaultDate[1])),
    );
 },

}
</script>
<style>
  .main-title {
    text-align: center
  }
  .master-container {
    position: absolute;
    width: 34vw;
    height: 33vh;
    left: 1vw;
    top: 20vh;  
  }

  .detail-container {
    position: absolute;
    left: 37vw;
    width: 60vw;
    top: 20vh;  
    height: 68vh;
  }

  .dymanic-detail-container {
    position: absolute;
    left: 1vw;
    top: 64vh;  
    height: 33vh;
  }
  
  .filter-panel {
    position: absolute;
    left: 1vw;
    top: 14vh;  
    width: 95.5vw;
  }

  .datebox-container {
    width: 12vw;
  }

  .help-button {
    position: absolute;
    right: 0vw;
  }

  .deduplication-mode-switcher {
    justify-content: normal;
    left: 27vw;
    position: absolute;
    top: 0;
  }

  .vs-popup--close--icon{
    background: white!important;
  }  
</style>
